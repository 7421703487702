<template>
  <BodySimple :loading="loadAccount || loadSetting">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-gears-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">مدیریت دسته‌بندی‌ها</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <!-- branchs-->

      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-merge-git-96.png" class="inline-block h-20" />
          دسته بندی ها
        </div>
        <p class="p-2">
          با ایجاد دسته بندی های مختلف میتوانید برای کاربران مختلف تنظیمات مختلفی در نظر
          بگیرید
        </p>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <div
            v-for="setting in group.settings"
            :key="setting.id"
            class="bg-blueGray-50 border border-blueGray-200 p-3 rounded-md"
          >
            <div class="border-b border-blueGray-200 pb-3">
              <img
                src="@/assets/images/icons8-settings-96.png"
                class="inline-block h-10"
              />
              {{ setting.name }}
            </div>
            <div class="p-2" v-if="setting.default">دسته بندی پیشفرض</div>
            <div class="flex p-2" v-else>
              <div class="w-1/2">کاربران</div>
              <div class="w-1/2 text-left">
                {{ L_.find(user_count, ["setting_id", setting.id])?.count ?? 0 }}
              </div>
            </div>
            <div class="flex p-2 pb-0 gap-2">
              <Button
                :to="{
                  name: 'GroupOwnerSettingBranchsEdit',
                  params: {
                    id: $store.state.user.id,
                    groupId: group.id,
                    branchId: setting.id,
                  },
                }"
                class="mt-3 text-sm h-11 lg:text-lg"
                color="teal"
              >
                ویرایش نام
              </Button>
              <Button
                :to="{
                  name: 'GroupOwnerSettingShowUsers',
                  params: { id: $store.state.user.id, groupId: group.id },
                  query: {
                    proSearch: 1,
                    branch: setting.id,
                  },
                }"
                class="mt-3 text-sm h-11 lg:text-lg"
                color="cyan"
              >
                کاربران
              </Button>
            </div>
            <div class="flex p-2 pt-0">
              <Button
                :to="{
                  name: 'GroupOwnerSettingUser',
                  params: {
                    id: $store.state.user.id,
                    groupId: group.id,
                    settingId: setting.id,
                  },
                }"
                class="mt-3 text-sm h-11 lg:text-lg"
                color="amber"
              >
                تنظیمات این دسته‌بندی
              </Button>
            </div>
          </div>
        </div>
        <Button
          :to="{
            name: 'GroupOwnerSettingBranchsNew',
            params: { id: $store.state.user.id, groupId: group.id },
          }"
          class="mt-3 inline-flex text-sm h-11 lg:text-lg"
          color="teal"
        >
          اضافه کردن
        </Button>
      </div>

      <!-- accounts-->

      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5"
        id="accounts"
      >
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-compare-git-96.png"
            class="inline-block h-20"
          />
          اشتراک ها
        </div>
        <ul class="my-5 list-inside list-disc">
          <li>
            با ایجاد اشتراک و اتصال آنها به دسته بندی مورد نظر کاربران با خرید اشتراک به
            طور خودکار در دسته مورد نظر قرار میگیرند
          </li>
          <li>
            به هر دسته می‌توان چند اشتراک متصل کرد مثلا اشتراک یک ماهه و ۳ ماهه با قیمت
            های متفاوت
          </li>
        </ul>
        <div class="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3 mt-3">
          <div
            v-for="account in accounts"
            :key="account.id"
            class="bg-blueGray-50 border border-blueGray-200 p-3 rounded-md"
          >
            <div class="border-b border-blueGray-200 pb-3">
              <img
                src="@/assets/images/icons8-membership-card-96.png"
                class="inline-block h-10"
              />
              {{ account.name }}
            </div>
            <div class="flex p-2">
              <div class="w-1/2">دسته بندی</div>
              <div class="w-1/2 text-left">{{ account.setting.name }}</div>
            </div>
            <div class="flex p-2">
              <div class="w-1/2">قیمت</div>
              <div class="w-1/2 text-left">{{ $number.format(account.price) }}</div>
            </div>
            <div class="flex p-2">
              <div class="w-1/2">مدت</div>
              <div class="w-1/2 text-left">{{ account.days }}</div>
            </div>
            <div class="p-2 h-20 overflow-hidden">
              {{ account.info }}
            </div>
            <div class="flex p-2 gap-2">
              <Button
                :to="{
                  name: 'GroupOwnerSettingAccountEdit',
                  params: {
                    id: $store.state.user.id,
                    groupId: group.id,
                    accountId: account.id,
                  },
                }"
                class="mt-3 text-sm h-11 lg:text-lg"
                color="teal"
              >
                ویرایش
              </Button>
              <Button
                @click="deleteAccount(account.id)"
                class="mt-3 text-sm h-11 lg:text-lg"
                color="red"
              >
                حذف
              </Button>
            </div>
          </div>
        </div>
        <Button
          :to="{
            name: 'GroupOwnerSettingAccountNew',
            params: { id: $store.state.user.id, groupId: group.id },
          }"
          class="mt-3 inline-flex text-sm h-11 lg:text-lg"
          color="teal"
        >
          اضافه کردن
        </Button>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import _ from "lodash";
import Button from "@/components/Button.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
  },
  data() {
    return {
      firstLoading: true,
      group: {},
      accounts: [],
      loadAccount: true,
      loadSetting: true,
      L_: _,
    };
  },
  mounted() {
    this.getSettings();
    this.getAccounts();
  },
  methods: {
    getSettings() {
      let $this = this;
      $this.$axios
        .get("/api/group-settings/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.user_count = response.data.user_count;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.loadSetting = false;
        });
    },
    getAccounts() {
      let $this = this;
      $this.$axios
        .get("/api/group-accounts/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.accounts = response.data.accounts;
          // $this.user_count = response.data.user_count;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.loadAccount = false;
        });
    },
    deleteAccount(id) {
      // console.log(id);
      // _.remove(this.accounts, ["id", id]);
      let $this = this;
      $this.errors = [];
      $this.firstLoading = true;
      $this.$axios
        .delete("/api/group-accounts/" + $this.$route.params.groupId + "/" + id, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          _.remove($this.accounts, ["id", id]);
        })
        .catch(function (error) {
          // console.log(error);
          $this.errors = error.data;
        })
        .then(function () {
          $this.firstLoading = false;
        });
    },
  },
};
</script>

<style></style>
